import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Footer } from '../components/footer';
import ButtonShareMobile from '../components/button/ButtonShareMobile';
import ButtonShareDesktop from '../components/button/ButtonShareDesktop';
import ButtonCta from '../components/button/ButtonCta';
import Navbar from '../components/navbar';
import Wrapper from '../components/Wrapper';

const BlogDetail = () => (
  <Wrapper>
    <Navbar />
    <div className="container mx-auto relative p-0">
      <div className="hidden absolute md:block lg:block pl-5 my-12">
        <ButtonShareDesktop />
      </div>
      <div className="mx-auto w-11/12 md:w-8/12 lg:w-8/12">
        <div className="my-4 md:my-5 lg:my-5 promo-label text-secondary capitalize">
          <span>Blog</span>
        </div>
        <div className="text-2xl font-bold md:text-4xl lg:text-4xl text-primary">
          <span>Catat! Ini Pentingnya Mengetahui Laporan Keuangan BPR</span>
        </div>
        <div className="text-sm font-bold my-3 text-primary">
          <span>Komunal Indonesia</span>
        </div>
        <div className="text-sm text-primary mb-10">
          <span>28 Januari 2022</span>
        </div>
        <div className="w-full mb-16">
          <StaticImage
            src="../images/artikel.png"
            alt="artikel-image"
            className="css-0"
            title="Catat! Ini Pentingnya Mengetahui Laporan Keuangan BPR"
            placeholder="none"
          />
        </div>
        <ButtonShareMobile />
        <div className="flex flex-row justify-center w-full mt-10 mb-20">
          <ButtonCta />
        </div>
      </div>
    </div>
    <Footer showConnectWithUs={false} />
  </Wrapper>
);

export default BlogDetail;
